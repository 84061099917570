


























import { Member } from "@/types/member";
import authModule from "@/store/modules/user";
import groupModule from "@/store/modules/groups";
import { Component, Vue, Prop } from "vue-property-decorator";
import { User } from "@/types/user";

@Component
export default class MemberItem extends Vue {
  @Prop() member!: Member;

  get loggedUser(): User | undefined {
    return authModule.user;
  }

  get isAdmin(): boolean {
    if (!groupModule.group) return false;
    return groupModule.group.user_id === this.member.id;
  }
}










































import Vue from "vue";
import Component from "vue-class-component";
import groupModule from "@/store/modules/groups";
import memberModule from "@/store/modules/members";
import authModule from "@/store/modules/user";
import { Group } from "@/types/group";
import { Member } from "@/types/member";
import MemberItem from "@/components/MemberItem.vue";
import { User } from "@/types/user";
import { GroupStatus } from "@/types/helpers";
import Paginate from "@/components/utility/Paginate.vue";

@Component({
  components: {
    MemberItem,
    Paginate,
  },
})
export default class GroupMembers extends Vue {
  status = GroupStatus;

  get user(): User | undefined {
    return authModule.user;
  }

  get members(): Member[] {
    return memberModule.pending;
  }

  get group(): Group | undefined {
    return groupModule.group;
  }

  async changeStatus(member: Member, status: number): Promise<void> {
    if (!this.group) return;
    const groupId = this.group.id;
    try {
      await memberModule.changeStatus({ groupId, member, status });
      this.$toast.success(this.$t("global.success").toString());
    } catch (err) {
      this.$toast.error(err.response.data.message);
    }
  }
}
